/* custom.css */
.p-datatable {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #000000;
  color: rgb(37, 37, 37);
  text-align: center;
  padding: 13px;
}

.p-datatable .p-datatable-tbody > tr {
  // border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
  
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #f9f9f9;
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: start;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 12px 8px;
  color: #555;
  border-bottom: none;
  font-family: sans-serif;
}

.p-datatable .p-datatable-tbody > tr:nth-of-type(even) {
  background-color: #fafafa;
}

/* Estilização do paginator */
.p-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  margin: 0 4px;
  padding: 6px 12px;
  border-radius: 4px;
  color: #982549;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-first.p-highlight,
.p-paginator .p-paginator-prev.p-highlight,
.p-paginator .p-paginator-next.p-highlight,
.p-paginator .p-paginator-last.p-highlight {
  background-color: #982549;
  color: white;
  border-color: white;
}

.p-paginator .p-paginator-pages .p-paginator-page:hover,
.p-paginator .p-paginator-first:hover,
.p-paginator .p-paginator-prev:hover,
.p-paginator .p-paginator-next:hover,
.p-paginator .p-paginator-last:hover {
  background-color: #982549;
  color: white;
}
