.calendar  {
  border-radius: 6px;
  font-size: 20px;
}

.p-calendar {
  border:2px solid rgb(109, 109, 109);
  border-radius: 4px;
  height: 38px;
  padding:0 0 0 5px
  
}

.p-inputtext {
  width: 300px;
  font-size: 18px;
}
.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;
  z-index: 2000 !important;
}

.p-datepicker-today {
  list-style: circle;
  background-color: #982549;
  color: white;
  border-radius: 10px;
}

.p-highlight {
  list-style: circle;
  background-color: #f3a0b5;
  color: #982549;;
  border-radius: 10px;
}